import React from 'react';
import CheckEmptyIcon from "./CheckEmptyIcon.jsx";
import DeleteIcon from "./DeleteIcon";
import {useDispatch} from "react-redux";
import {toggleTodoAction} from '../store/actions/actionCreators.js';


function TodoItem(props) {
  
  const dispatch = useDispatch();
  const {t: {id, title, isCompleted}} = props;
  
  
  return (
      <div className="flex mb-3">
        <div
          className="flex items-center mr-1 h-16"
          onClick={() => dispatch(toggleTodoAction(id))}
        >
          <CheckEmptyIcon isCompleted={isCompleted}/>
        </div>
        <div className={isCompleted ? 'line-through  text-zinc-300 flex items-center bg-zinc-800 p-5 w-full mr-1 h-16' : ' text-zinc-300 flex items-center bg-zinc-800 p-5 w-full mr-1 h-16'}>{title}</div>
        <DeleteIcon
          isCompleted={isCompleted}
          id={id}
        />
      </div>
  );
}

export default TodoItem;
