import {ADD_TODO, DELETE_TODO, TOGGLE_TODO, GET_INPUT_VALUE} from './actionTypes.js';


export function getInputValueAction(payload) {
  return {
    type: GET_INPUT_VALUE,
    payload
  }
}

export function toggleTodoAction(payload) {
  return {
    type: TOGGLE_TODO,
    payload
  }
}

export function addTodoAction() {
  return {
    type: ADD_TODO,
  }
}

export function deleteTodoAction(payload) {
  return {
    type: DELETE_TODO,
    payload
  }
}
