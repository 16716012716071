import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Home from "./pages/Home.jsx";
import Layout from "./layouts/Layout";
import {store} from './store/';
import {Provider} from 'react-redux';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Layout>
        <Home />
      </Layout>
    </Provider>
  </React.StrictMode>
);
