import React, {useEffect} from 'react';
import TodoItem from "../components/TodoItem";
import AddTodoItem from "../components/AddTodoItem";
import {useSelector} from "react-redux";


function Home() {
  
  const todos = useSelector(state => state.todoReducer.todos);
  
  useEffect(() => {
    localStorage.setItem('todos', JSON.stringify(todos))
  }, [todos])
  
  
  return (
    <div className="w-2/3 mx-auto ">
      <h1 className="text-xl font-bold text-center mb-8">Todos List</h1>
      <AddTodoItem />
      {
        todos.map((t) =>
          <TodoItem
              t={t}
              key={t.id}
            />
        )
      }
    </div>
  );
}

export default Home;
