import {ADD_TODO, DELETE_TODO, TOGGLE_TODO, GET_INPUT_VALUE} from '../actions/actionTypes.js';


const initialState = {
  todos: JSON.parse(localStorage.getItem('todos')) || [],
  //-todos: [{
  //  id: 1,
  //  title: 'Test Title',
  //  isCompleted: false
  //}],
  todo: {
    id: null,
    title: '',
    isCompleted: false
  },
  inputTodo: '',
  isInputValue: false,
}

function todoReducer(state = initialState, action) {
  switch (action.type) {
    //========================================================
    case GET_INPUT_VALUE:
      return {
        ...state,
        inputTodo: action.payload ? action.payload : '',
        isInputValue: action.payload
      }
    //========================================================
    case TOGGLE_TODO:
      return {
        ...state,
        todos: state.todos.map(todo => {
          if (todo.id !== action.payload) {
            return todo;
          }
          return {...todo, isCompleted: !todo.isCompleted}
          
        }),
      }
    //========================================================
    
    case ADD_TODO:
      if(state.inputTodo === '') {
        return state;
      }
      return {
        ...state,
        todos: [{
          id: Math.random(),
          title: state.inputTodo,
          isCompleted: false
        }, ...state.todos],
        inputTodo: '',
        isInputValue: false,
      }
    
    //========================================================
    
    case
    DELETE_TODO:
      return {...state, todos: state.todos.filter(t => t.id !== action.payload)}
    default:
      return state
  }
}

export default todoReducer;
