import React from 'react';
import {BsCheckCircleFill} from "react-icons/bs";
import cn from 'classnames';

function     CheckEmptyIcon({isCompleted}) {

  return (
    <button className="bg-zinc-800 rounded-l-2xl py-6 h-16">
      {
        isCompleted && <BsCheckCircleFill className="text-pink-500 mx-2 hover:text-pink-800 transition-colors ease-in-out duration-300" size={20}/>
      }
      <div className={cn('border-2 rounded-lg border-pink-400 w-5 h-5 mx-2 hover:border-pink-800 transition-colors ease-in-out duration-300', {'hidden': isCompleted})}></div>
    </button>
  );
}

export default CheckEmptyIcon;
