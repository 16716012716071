import React from 'react';
import {MdOutlineAddCircle} from 'react-icons/md';
import {useDispatch, useSelector} from "react-redux";
import {addTodoAction, getInputValueAction} from '../store/actions/actionCreators.js';



function AddTodoItem() {
  
  const dispatch = useDispatch();
  const isInputValue = useSelector(state => state.todoReducer.isInputValue)
  const inputTodo = useSelector(state => state.todoReducer.inputTodo)
  
  
  return (
    <div className="flex justify-start text-zinc-400 align-middle border-2 border-zinc-800 rounded-2xl h-10 mb-8">
      <button
        onClick={() => dispatch(addTodoAction())}
        className="self-center disabled:opacity-20"
        disabled={!isInputValue}
      >
        <MdOutlineAddCircle className="text-pink-500 mx-2 hover:text-pink-800 transition-colors ease-in-out duration-300 cursor-pointer" size={24}/>
      </button>
      <div className="bg-zinc-900 w-full">
        <input
          onChange={(e) => dispatch(getInputValueAction(e.target.value))}
          onKeyUp={(e) => e.key === 'Enter' && dispatch(addTodoAction())}
          type="text"
          placeholder="Input any task"
          className="bg-zinc-900 h-full w-full"
          value={inputTodo}
        />
      </div>
    </div>
  );
}

export default AddTodoItem;
